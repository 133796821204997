import React, { useEffect, useRef, useState } from 'react';
import * as faceapi from 'face-api.js';

const FaceDetection = ({
  onplayPromoMessage,  
  onsessionInfo,
  onstartspeech,
  styles,
  aiFormData,
  rizwanScriptStep
}) => {
const videoRef = useRef(null);
const [distance, setDistance] = useState(null);
const [isInRange, setIsInRange] = useState(false); // Tracks current range status
const enterRangeTimeRef = useRef(null); // Ref to store the time when person enters the range
const hasTriggeredRef = useRef(false); // Ref to track if the function has been triggered
const onsessionInfoRef = useRef(onsessionInfo);
const rizwanScriptStepRef= useRef(rizwanScriptStep);

const isFaceDetectionPaused = useRef(null);


useEffect(() => {
  onsessionInfoRef.current = onsessionInfo;
}, [onsessionInfo]);

useEffect(()=>{
  rizwanScriptStepRef.current=rizwanScriptStep;
},[rizwanScriptStep])

// Calibration values
const referenceDistance = 1; // known distance in meters
const referenceFaceWidthInPixels = 100; // this should be calibrated for your setup

// Calculate focal length based on calibration
const focalLength = (referenceFaceWidthInPixels * referenceDistance);

useEffect(() => {
  const loadModels = async () => {
    await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
    await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
    await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
    await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
  };

  const startVideo = () => {
    navigator.mediaDevices.getUserMedia({ video: {} })
      .then((stream) => {
        videoRef.current.srcObject = stream;
      })
      .catch((err) => console.error("Error accessing webcam: ", err));
  };

  loadModels().then(startVideo);

  console.log("isFaceDetectionPaused.current",isFaceDetectionPaused.current)

  const detectFace = async () => {
    if (!isFaceDetectionPaused.current && videoRef.current && videoRef.current.readyState === 4) {
      const result = await faceapi.detectSingleFace(videoRef.current).withFaceLandmarks();
      
      if (result) {
        const faceBox = result.detection.box;
        const faceWidth = faceBox.width;

        // Distance estimation using calibrated focal length
        const distanceEstimate = (focalLength / faceWidth).toFixed(2);
        setDistance(distanceEstimate);

        const splitTimeRange= aiFormData?.fecedetection_time_range.split(";").map(Number);

        if (distanceEstimate >= splitTimeRange[0] && distanceEstimate <= splitTimeRange[1]) {
          if (!isInRange) {
            // Person has entered the range
            setIsInRange(true);
            enterRangeTimeRef.current = Date.now();
             hasTriggeredRef.current = false; // Reset trigger flag
          } else if (!hasTriggeredRef.current && Date.now() - enterRangeTimeRef.current >= aiFormData?.time_rane*1000) {
            // Trigger function if the person has been in range for 3 seconds
            triggerFunction();
            hasTriggeredRef.current = true; // Prevent multiple triggers
          }
        } else {
          if (isInRange) {
            // Person has left the range
            setIsInRange(false);
            enterRangeTimeRef.current = null;
          }
        }
      }else {
        // No face detected
        // setDistance(null); // Set distance to null when no face is detected
        // setIsInRange(false); // Reset in-range status
        // hasTriggeredRef.current = false; // Reset trigger flag
      }
    }
  };

  const interval = setInterval(detectFace, 100); // Detect face every 100ms

  return () => clearInterval(interval); // Clean up interval on component unmount
}, [isInRange]);

// Your trigger function
const triggerFunction = () => {
  console.log('Person has been within the distance range for 3 seconds!');
  

  // alert(rizwanScriptStepRef.current);

  if(rizwanScriptStepRef.current===2 && aiFormData.enable_rizwan_event==='yes'){
   
    onstartspeech();
  }

  if(rizwanScriptStepRef.current===1 && aiFormData.enable_rizwan_event==='yes'){

    const rizwanStatus = aiFormData.enable_rizwan_event==='yes' ? true : false;
    
    setDistance(null);
    onplayPromoMessage(onsessionInfoRef.current,rizwanStatus);  

       // Pause face detection for 10 seconds
    isFaceDetectionPaused.current=true;    

    hasTriggeredRef.current = true;
    setIsInRange(false);
    enterRangeTimeRef.current = null;
    
    setTimeout(() => {
      console.log("")
      isFaceDetectionPaused.current=null;
    }, 12000); 

  }

  if(aiFormData.enable_rizwan_event!=='yes' && !hasTriggeredRef.current ){
    onplayPromoMessage(onsessionInfoRef.current);
  }
  
  
};

return (
  <div id='face_detection_location' style={styles}>
    <video ref={videoRef} autoPlay muted width="180" height="140" style={{visibility: aiFormData.disable_camera_front === 'yes' ? 'hidden' : 'visible'}} />
    {distance && (
      <div>
        <h3 style={{fontSize:"10px",visibility: aiFormData.disable_camera_front === 'yes' ? 'hidden' : 'visible'}}>Distance from Screen: {distance} meters</h3>
      </div>
    )}
  </div>
);
};

export default FaceDetection;


