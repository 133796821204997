import React, { useContext } from 'react';
import { AuthContext } from '../../AuthContext'


export default function Leftbar() {
	const { authState, loading } = useContext(AuthContext);


  return (
    <div className="left-side-bar">
			<div className="brand-logo">
				<a href="/backend" >AI Human

					{/* <h3 style={{color:"#fff;"}}>AI Human</h3> */}
					{/* <img src="/vendors/images/deskapp-logo.svg" alt="" className="dark-logo" id="dark-logo" />
					<img
						src="/vendors/images/deskapp-logo-white.svg"
						alt=""
						className="light-logo"
					/> */}
				</a>
				<div className="close-sidebar" data-toggle="left-sidebar-close">
					<i className="ion-close-round"></i>
				</div>
			</div>
			<div className="menu-block customscroll">
				<div className="sidebar-menu">
					<ul id="accordion-menu">	
					{authState.token && authState.user.role === 1 && (
						<>
							<li>
							<a href="/add-client" className='dropdown-toggle no-arrow'>
								<span className="micon bi bi-calendar4-week"></span>
								<span className="mtext">Add Client</span>
							</a>
							</li>
							<li>
							<a href="/view-clients" className='dropdown-toggle no-arrow'>
								<span className="micon bi bi-calendar4-week"></span>
								<span className="mtext">View Clients</span>
							</a>

							<a href="/view-client-logs" className='dropdown-toggle no-arrow'>
								<span className="micon bi bi-calendar4-week"></span>
								<span className="mtext">View Logs</span>
							</a>
							
							</li>
						</>
						)}


					{authState.token && authState.user.role === 2 && (
					<>
					<li>
						<a href={`/`} className='dropdown-toggle no-arrow'>
							<span className="micon bi bi-calendar4-week"></span>
							<span className="mtext">Settings</span>
						</a>
						</li>
						<li>
						<a href={`/${authState.user.client_url}`} className='dropdown-toggle no-arrow' target='_blank'>
							<span className="micon bi bi-calendar4-week"></span>
							<span className="mtext">View Frontend</span>
						</a>
						</li>
						
					</>
					)}


						<li>
							<a href={`/view-profile/${authState.user._id}`} className='dropdown-toggle no-arrow'>
								<span className="micon bi bi-calendar4-week"></span>
								<span className="mtext">Profile</span>
							</a>
						</li>
	
						
										
						
						<li>
							<div className="dropdown-divider"></div>
						</li>
						
					</ul>
				</div>
			</div> 
		</div>
  )
}
